import { BulletListArrow } from '@/icons/BulletListArrow';
import { Grid, Typography } from '@mui/material';
import React from 'react';

export type Props = {
  text: string;
};

export const BulletList = ({ text }: Props) => (
  <Grid container sx={{ alignItems: 'center', mb: 2 }}>
    <Grid item sx={{ fontSize: 0, mr: 1 }}>
      <BulletListArrow />
    </Grid>
    <Grid item xs={10}>
      <Typography>{text}</Typography>
    </Grid>
  </Grid>
);
