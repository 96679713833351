import { MainColumn } from '@/components/main-column';
import React from 'react';
import { StrapiServicesSection3 } from '../../../../api/services-page';
import { SectionWithList } from '../../section-with-list';

export type Props = {
  strapiSection: StrapiServicesSection3;
};

export const Section3 = ({ strapiSection }: Props) => (
  <MainColumn>
    <SectionWithList section={strapiSection} />
  </MainColumn>
);
