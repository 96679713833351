import React, { FC } from 'react';
import {
  StrapiService,
  StrapiServicesSection3,
} from '../../../api/services-page';
import { Box, Grid, Typography, Theme, useMediaQuery } from '@mui/material';
import { MarkdownContent } from '@/components/markdown-content';
import { TrackedButton } from '@/components/tracked-button';
import { Link as GatsbyLink } from 'gatsby';
import { BulletList } from '../bullet-list';
import { IconStack } from '../icon-stack';

export type Props = {
  section: StrapiServicesSection3;
};

export type ServicesImage = {
  imageUrl: string;
};

export const SectionWithList = ({ section }: Props) => {
  const servicesArray = section?.services?.data as StrapiService[];
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const isLeftImage = (imageIndex: number) => imageIndex % 2 !== 0;
  const Image: FC<ServicesImage> = ({ imageUrl }) => (
    <Grid
      xs={12}
      md={5}
      item
      sx={{
        height: '100%',
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        mb: { xs: 4, md: '0px' },
      }}
    >
      <Box
        sx={{
          width: { md: '100%', sm: '100%', xs: '100%' },
          px: { sm: '24px', xs: '24px' },
          height: { md: '620px', sm: '480px', xs: '343px' },
          backgroundImage: `url(${imageUrl})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </Grid>
  );

  return (
    <>
      {servicesArray?.map(
        (
          {
            id,
            attributes: {
              button,
              icon,
              image,
              name,
              paragraph,
              slug,
              section1,
              section3,
            },
          },
          idx,
        ) => (
          <Grid
            key={`${id}${slug}`}
            container
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
            sx={{
              backgroundColor: 'greyscale.white !important',
              my: {
                md: '100px',
                xs: idx !== servicesArray?.length - 1 ? 6 : '0px',
              },
            }}
          >
            {(isLeftImage(idx) || !isMobile) && (
              <Image imageUrl={image?.data?.attributes?.url || ''} />
            )}
            <Grid xs={12} md={7} item>
              <Box
                sx={{
                  color: 'greyscale.indigo',
                  pl: {
                    md: isLeftImage(idx) && isMobile ? '64px' : '0',
                    sm: '0px',
                  },
                  pr: {
                    md: !isLeftImage(idx) ? '64px' : '0',
                    sm: '0px',
                  },
                }}
              >
                <Box
                  sx={{
                    fontSize: 0,
                    mb: { xs: 2, md: 3 },
                  }}
                >
                  <Box
                    component={'img'}
                    src={icon?.data?.attributes?.url}
                    sx={{
                      maxWidth: { xs: '48px', md: '150px' },
                      maxHeight: '100px',
                      objectFit: 'cover',
                    }}
                  />
                </Box>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 2,
                  }}
                >
                  {name}
                </Typography>
                <MarkdownContent
                  content={paragraph}
                  sx={{
                    mb: { xs: 3, md: 4 },
                  }}
                />
                <Box
                  sx={{
                    mb: { xs: 3, md: 4 },
                  }}
                >
                  {section1?.paragraph1List?.map(
                    ({ text }: { text: string }, index) => (
                      <BulletList key={`${text}${index}`} text={text} />
                    ),
                  )}
                </Box>
                <Box
                  sx={{
                    mb: { xs: 3, md: 4 },
                  }}
                >
                  <IconStack logos={section3?.paragraph3Logos?.data} />
                </Box>
                <GatsbyLink
                  to={`/services/${slug}`}
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  <TrackedButton variant={'outlined'}>
                    {button?.text}
                  </TrackedButton>
                </GatsbyLink>
              </Box>
            </Grid>
            {!isLeftImage(idx) && isMobile && (
              <Image imageUrl={image?.data?.attributes?.url || ''} />
            )}
          </Grid>
        ),
      )}
    </>
  );
};
