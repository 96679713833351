import { styled } from '@mui/system';
import { GatsbyImage } from 'gatsby-plugin-image';

export const StyledImageIcon = styled(GatsbyImage)(({ theme }) => ({
  [theme.breakpoints.up('xs')]: {
    maxWidth: '32px',
    maxHeight: '32px',
  },
  [theme.breakpoints.up('md')]: {
    maxWidth: '40px',
    maxHeight: '40px',
  },
}));
