import { StrapiImage } from '@/api/types';
import { Box, Stack } from '@mui/material';
import React from 'react';
import getGatsbyImageData from '@/utils/getGatsbyImageData';
import { StyledImageIcon } from '@/components/icon-stack/IconStack.style';

export type Props = {
  logos: StrapiImage[] | undefined;
};

export const IconStack = ({ logos }: Props) => (
  <Stack
    direction="row"
    spacing={{ xs: 5, md: 6 }}
    sx={{
      px: { xs: '0px', md: 3 },
    }}
  >
    {logos?.map((logo, index) => {
      const logoImageData = getGatsbyImageData(logo);
      return (
        <Box
          key={index}
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          {logoImageData && (
            <StyledImageIcon
              alt={logo?.attributes?.alternativeText || ''}
              image={logoImageData}
            />
          )}
        </Box>
      );
    })}
  </Stack>
);
